<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'turmas' })"
            :disabled="false"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Turma
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="turma">
        <v-card color="green lighten-1" class="mb-5" outlined>
          <v-card-title> Turma: {{ turma.descricao }}</v-card-title>
          <v-card-subtitle class="font-weight-medium">
            <div>
              Professor(a): <strong>{{ professor.nome || "- - -" }}</strong>
            </div>
            <div>
              Total de alunos: <strong>{{ matriculas.length || "- - -" }}</strong>
            </div>
            <div>
              Tipo de atendimento: <strong>{{ turma.tipo_de_atendimento || "- - -" }}</strong> |
              Tipo de ensino:
              <strong>{{ turma.curso?.descricao || "- - -" }}</strong>
            </div>
            <div>
              Tipo de relaltório:
              <strong>{{ turma.sistema_bncc_id > 0 ? "BNCC" : "Personalizado" }}</strong>
            </div>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-3">
      <template>
        <v-tabs v-model="tab_atual" background-color="primary" dark>
          <v-tab v-for="tab of tabs" :key="tabs.indexOf(tab)">{{ tab.descricao }}</v-tab>
        </v-tabs>
        <div class="text-right">
          <v-btn color="primary" class="ml-4 mt-4 mr-4 white--text" @click="gerarRelatorio">
            Lista da turma
            <v-icon right dark> mdi-file </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-simple-table v-if="matriculas" class="elevation-1" v-show="tab_atual == 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">N º</th>
                  <th class="text-left" width="300px">Aluno</th>
                  <th class="text-center" width="15%">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="matricula of matriculas"
                  :key="matricula.id"
                  :class="{
                    accent: !matricula.aluno,
                    'red lighten-5': matricula.situacao === 'TRANSFERIDO',
                  }"
                >
                  <td width="20">{{ matriculas.indexOf(matricula) + 1 }}</td>
                  <td class="font-weight-medium text-left">
                    <div>
                      {{
                        matricula.aluno
                          ? matricula.aluno.nomecompleto
                          : `[ALUNO POSSIVELMENTE DELETADO] MATRICULA: ${matricula.id}`
                      }}
                      <v-chip
                        label
                        color="error"
                        small
                        v-if="
                          $constants.situacoesMatriculaCondition[
                            removerEspaco(matricula.situacao ? matricula.situacao : '')
                          ]
                        "
                        >{{ matricula.situacao }}</v-chip
                      >
                    </div>
                    <div>
                      <small>
                        {{ matricula.aluno ? matricula.aluno.codigo_inep : "- - -" }} /
                        {{ matricula.aluno ? matricula.aluno.codigo : "- - -" }}
                      </small>
                    </div>
                  </td>
                  <td class="text-center">
                    <v-tooltip
                      bottom
                      v-if="turma.sistema_bncc_id && matricula.situacao !== 'TRANSFERIDO'"
                    >
                      <!-- v-if="matricula.aluno?.especial === 0 && turma?.curso_id !== 3" -->
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="blue lighten-3"
                          v-bind="attrs"
                          v-on="on"
                          :to="{
                            name: 'relatorios.lancarRelatorioBNCC',
                            params: { matricula_id: matricula.id },
                          }"
                        >
                          <v-icon small>post_add</v-icon>
                        </v-btn>
                      </template>
                      <span>Lançar relatório da(e) {{ turma.curso.descricao }}</span>
                    </v-tooltip>
                    <v-tooltip
                      bottom
                      v-if="turma.sistema_bncc_id && matricula.situacao !== 'TRANSFERIDO'"
                    >
                      <!-- v-if="matricula.aluno?.especial === 0 && turma?.curso_id !== 3" -->
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-2"
                          color="red lighten-5"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => RelatorioBNCC(matricula.id)"
                        >
                          <v-icon color="red lighten-1" small>file_open</v-icon>
                        </v-btn>
                      </template>
                      <span> Gerar relatório da(e) {{ turma.curso.descricao }}</span>
                    </v-tooltip>

                    <!-- RELATÓRIO DESCRITIVO DOS ALUNOS DO INFANTIL -->
                    <v-tooltip
                      bottom
                      v-if="turma.sistema_bncc_id && matricula.situacao !== 'TRANSFERIDO'"
                    >
                      <!-- v-if="matricula.aluno?.especial === 0 && turma?.curso_id !== 3" -->
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-2"
                          color="blue lighten-4"
                          v-bind="attrs"
                          v-on="on"
                          :to="{
                            name: 'relatorios.lancarRelatorioDescritivo',
                            params: { matricula_id: matricula.id },
                          }"
                        >
                          <v-icon small>fas fa-file-invoice</v-icon>
                        </v-btn>
                      </template>
                      <span
                        >Lançar relatório descritivo de desenvolvimento da(e)
                        {{ turma.curso.descricao }}</span
                      >
                    </v-tooltip>
                    <v-tooltip
                      bottom
                      v-if="turma.sistema_bncc_id && matricula.situacao !== 'TRANSFERIDO'"
                    >
                      <!-- v-if="matricula.aluno?.especial === 0 && turma?.curso_id !== 3" -->
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-2"
                          color="red lighten-4"
                          v-bind="attrs"
                          v-on="on"
                          @click="openModal(matricula.id)"
                        >
                          <v-icon color="red lighten-1" small> fa fa-file-pdf</v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Gerar relatório descritivo de desenvolvimento da(e)
                        {{ turma.curso.descricao }}</span
                      >
                    </v-tooltip>
                    <!-- RELATÓRIO DESCRITIVO DOS ALUNOS DO INFANTIL -->

                    <!-- PARA ALUNOS ESPECIAIS -->
                    <v-tooltip bottom v-if="matricula.aluno.especial == 1 || turma.curso_id === 3">
                      <!-- v-if="matricula.aluno.especial == 1 && turma.curso_id === 3" -->
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="blue lighten-3"
                          v-bind="attrs"
                          v-on="on"
                          :to="{
                            name: 'educacaoInclusiva.create',
                            params: { matricula_id: matricula.id },
                          }"
                        >
                          <v-icon small>fa fa-universal-access</v-icon>
                        </v-btn>
                      </template>
                      <span>Lançar relatório AEE (ATENDIMENTO EDUCACIONAL ESPECIALIZADO)</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="matricula.aluno.especial === 1 || turma.curso_id === 3">
                      <!-- v-if="matricula.aluno.especial === 1 && turma.curso_id === 3" -->
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-2"
                          color="red lighten-5"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => relatorioAee(matricula.id)"
                        >
                          <v-icon color="red lighten-1" small> fa fa-file-pdf</v-icon>
                        </v-btn>
                      </template>
                      <span>Relatório AEE (ATENDIMENTO EDUCACIONAL ESPECIALIZADO)</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-dialog v-model="bimestreDialog" max-width="500px">
          <v-card>
            <v-card-title class="mb-4 text-h5 primary white--text">
              Selecionar Bimestre
            </v-card-title>
            <v-card-text>
              <v-select
                v-model="selectedBimestre"
                :items="bimestres"
                label="Selecione o Bimestre"
                item-text="text"
                item-value="value"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="bimestreDialog = false">Cancelar</v-btn>
              <v-btn color="primary" text @click="confirmSelection">Confirmar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-card>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      turma: null,
      matriculas: null,
      gestoes_de_aulas: null,
      tab_atual: 0,
      tabs: [
        {
          id: 0,
          descricao: "Lista da Turma",
        },
      ],
      bimestreDialog: false,
      selectedMatriculaId: null,
      selectedBimestre: null,
      bimestres: [
        { text: "1º Bimestre", value: 1 },
        { text: "2º Bimestre", value: 2 },
      ],
    };
  },
  async mounted() {
    await this.loadBasicData();
  },
  methods: {
    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
    async gerarRelatorio() {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.listarTurma(parseInt(this.$route.params.turmaId, 10));
      this.$loaderService.close();
    },
    async RelatorioBNCC(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.relatorioBNCC(parseInt(matricula_id, 10));
      this.$loaderService.close();
    },
    async relatorioAee(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.relatorioAee(parseInt(matricula_id, 10));
      this.$loaderService.close();
    },
    async RelatorioDescritivo(matricula_id, bimestre) {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.relatorioDescritivo(
        parseInt(matricula_id, 10),
        bimestre
      );
      this.$loaderService.close();
    },

    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      try {
        const payload = await this.$services.gestoesDeAulasService.visualizarTurma(
          parseInt(this.$route.params.gestaoDeAulasId, 10)
        );
        this.turma = payload.turma;
        this.professor = payload.professor;
        this.matriculas = payload.turma.matriculas;
      } catch (error) {
        this.$handleError(error);
      }

      this.$loaderService.close();
    },
    openModal(matriculaId) {
      this.selectedMatriculaId = matriculaId;
      this.bimestreDialog = true;
    },
    async confirmSelection() {
      this.bimestreDialog = false;
      await this.RelatorioDescritivo(this.selectedMatriculaId, this.selectedBimestre);
    },
  },
};
</script>

<style></style>
